import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/enrolment-token/[order_id]/[product_id]/generate": [5],
		"/enrolment": [3],
		"/enrolment/[order_id]/[product_id]": [4],
		"/notices": [6],
		"/notifications": [7],
		"/orders": [8],
		"/payments": [9],
		"/reporting": [10],
		"/reporting/draft-orders": [11],
		"/reporting/exceptions": [12],
		"/reports/orders": [13],
		"/teacher": [14],
		"/testimonials": [15]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';